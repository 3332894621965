import React, { useContext } from 'react'
import { GlobalDataContext } from "../../context/context";
// import { ButtonContent } from '../global/boton/ButtonContent';

const SlideLogoForm = ({ image, text }) => {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div className='bgCountent relative w-full min-h-[600px] h-[600px] md:h-[600px] ' style={{ backgroundImage: `url("${image ? image : rpdata?.gallery?.[1]}")` }}>
            <div className='absolute w-full h-auto'>
                <div className='w-4/5 mx-auto flex flex-col items-center justify-center text-center z-50 space-y-4'>
                    <span className='text-white font-semibold text-[20px]'>Visit Them</span>
                    <div className="w-[80%] md:w-[30%] flex justify-center mx-auto">
                        <a 
                        href='https://vasariplaster.com/'
                        rel='noreferrer'
                        target='_blank'
                        >
                          <img
                            src='https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/vasari.avif?alt=media&token=42f59793-7c12-4b90-a113-9a0d6b6256e4'
                            alt="CompanyLogo"
                            className="w-full flex bg-white p-2 rounded-lg"
                        />   
                        </a>
                       
                    </div>
                    <div className='text-white text-center'>
                        {/* <p>
                            {
                                rpdata?.dbValues?.[1]?.description
                            }     
                        </p> */}
                        <p className='pb-3'>
                            {
                                text ? text :
                                    <span>{`para agregar el texto -> text={'description'}`}
                                        <br />{'para agregar lista de about -> listsAbout={true}'}
                                        <br />{'para agregar lista de servicios -> listsServices={true}'}
                                    </span>
                            }
                        </p>
                    </div>

                    <div >
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SlideLogoForm;
